define('ember-indexeddb-adapter/adapters/indexeddb', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  'use strict';

  exports['default'] = _emberData['default'].Adapter.extend({
    /**
     * Database name
     * @type {string}
     */
    dbName: 'database',

    /**
     * Database version number.
     * Whenever this number is greater than the version number of the existing
     * database, this will update it's schema.
     * @type {number}
     */
    version: 1,

    /**
     * Open IndexedDB and upgrade if necessary
     */
    init: function init() {
      var self = this;
      var request = window.indexedDB.open(this.get('dbName'), this.get('version'));

      request.onerror = function () {
        console.log('Unable to open IndexedDB');
      };

      request.onupgradeneeded = function (event) {
        var db = event.target.result;

        db.onerror = function (event) {
          console.log('IndexedDB error: ' + event.target.errorCode);
        };

        self.get('models').forEach(function (model) {
          if (!db.objectStoreNames.contains(model)) {
            db.createObjectStore(model, { keyPath: 'id' });
          }
        });
      };
    },

    /**
     * Open IndexedDB
     * return {promise} Promise that contains an IDBOpenDBRequest instance
     */
    openDatabase: function openDatabase() {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        var request = window.indexedDB.open(_this.get('dbName'));

        request.onerror = function (event) {
          console.log('Unable to open IndexedDB');
          _ember['default'].run(null, reject, event);
        };

        request.onsuccess = function (event) {
          _ember['default'].run(null, resolve, event.target.result);
        };
      });
    },

    /**
     * Generate a random number for ID
     * return {string} Generated ID
     */
    generateIdForRecord: function generateIdForRecord() {
      return Math.random().toString(32).slice(2).substr(0, 5);
    },

    /**
     * Create new record
     * param {object} store DS.Store
     * param {object} type DS.Model class of the record
     * param {object} snapshot DS.Snapshot
     * return {promise} Promise that contains the record
     */
    createRecord: function createRecord(store, type, snapshot) {
      var _this2 = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _this2.openDatabase().then(function (db) {
          var data = _this2.serialize(snapshot, { includeId: true });
          var modelName = type.modelName;
          var objectStore = db.transaction([modelName], 'readwrite').objectStore(modelName);
          var request = objectStore.add(data);

          request.onsuccess = function () {
            db.close();
            _ember['default'].run(null, resolve, data);
          };

          request.onerror = function (event) {
            console.log('IndexedDB error: ' + event.target.errorCode);
            db.close();
            _ember['default'].run(null, reject, event);
          };
        });
      });
    },

    /**
     * Find record by it's ID
     * param {object} store DS.Store
     * param {object} type DS.Model
     * param {string} id ID
     * return {promise} Promise that contains the record
     */
    findRecord: function findRecord(store, type, id) {
      var _this3 = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _this3.openDatabase().then(function (db) {
          var modelName = type.modelName;
          var objectStore = db.transaction([modelName]).objectStore(modelName);
          var request = objectStore.get(id);

          request.onsuccess = function () {
            db.close();
            _ember['default'].run(null, resolve, request.result);
          };

          request.onerror = function (event) {
            console.log('IndexedDB error: ' + event.target.errorCode);
            db.close();
            _ember['default'].run(null, reject, event);
          };
        });
      });
    },

    /**
     * Find all records
     * param {object} store DS.Store
     * param {object} type DS.Model
     * return {promise} Promise that contains the record
     */
    findAll: function findAll(store, type) {
      var _this4 = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _this4.openDatabase().then(function (db) {
          var data = [];
          var modelName = type.modelName;
          var objectStore = db.transaction([modelName]).objectStore(modelName);
          var request = objectStore.openCursor();

          request.onsuccess = function (event) {
            var cursor = event.target.result;

            if (cursor) {
              data.push(cursor.value);
              cursor['continue']();
            } else {
              _ember['default'].run(null, resolve, data);
            }
          };

          request.onerror = function (event) {
            console.log('IndexedDB error: ' + event.target.errorCode);
            db.close();
            _ember['default'].run(null, reject, event);
          };
        });
      });
    },

    /**
     * Update record
     * param {object} store DS.Store
     * param {object} type DS.Model class of the record
     * param {object} snapshot DS.Snapshot
     * return {promise} Promise that contains the record
     */
    updateRecord: function updateRecord(store, type, snapshot) {
      var _this5 = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _this5.openDatabase().then(function (db) {
          var data = _this5.serialize(snapshot, { includeId: true });
          var modelName = type.modelName;
          var objectStore = db.transaction([modelName], 'readwrite').objectStore(modelName);
          var request = objectStore.get(snapshot.id);

          request.onsuccess = function () {
            var requestUpdate = objectStore.put(data);

            requestUpdate.onsuccess = function () {
              db.close();
              _ember['default'].run(null, resolve, data);
            };

            requestUpdate.onerror = function (event) {
              console.log('IndexedDB error: ' + event.target.errorCode);
              db.close();
              _ember['default'].run(null, reject, event);
            };
          };
        });
      });
    },

    /**
     * Delete record
     * param {object} store DS.Store
     * param {object} type DS.Model class of the record
     * param {object} snapshot DS.Snapshot
     * return {promise} Promise that contains the record
     */
    deleteRecord: function deleteRecord(store, type, snapshot) {
      var _this6 = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _this6.openDatabase().then(function (db) {
          var data = _this6.serialize(snapshot, { includeId: true });
          var modelName = type.modelName;
          var objectStore = db.transaction([modelName], 'readwrite').objectStore(modelName);
          var request = objectStore['delete'](snapshot.id);

          request.onsuccess = function () {
            db.close();
            _ember['default'].run(null, resolve, data);
          };

          request.onerror = function () {
            console.log('IndexedDB error: ' + event.target.errorCode);
            db.close();
            _ember['default'].run(null, reject, event);
          };
        });
      });
    },

    /**
     * TODO: See if using index is feasible
     *
     * Find a record that matches the query
     * param {object} store DS.Store
     * param {object} type DS.Model
     * param {object} query Query object
     * return {promise} Promise that contains the record
     */
    queryRecord: function queryRecord(store, type, query) {
      var _this7 = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _this7.openDatabase().then(function (db) {
          var modelName = type.modelName;
          var queryKeys = Object.keys(query);
          var objectStore = db.transaction([modelName]).objectStore(modelName);
          var request = objectStore.openCursor();

          request.onsuccess = function (event) {
            var cursor = event.target.result;

            if (cursor) {
              (function () {
                var queryKeyMatchCount = 0;

                queryKeys.forEach(function (key) {
                  if (cursor.value[key] === query[key]) {
                    queryKeyMatchCount++;
                  }
                });

                if (queryKeyMatchCount === queryKeys.length) {
                  db.close();
                  _ember['default'].run(null, resolve, cursor.value);
                } else {
                  cursor['continue']();
                }
              })();
            } else {
              db.close();
              _ember['default'].run(null, resolve, null);
            }
          };

          request.onerror = function (event) {
            console.log('IndexedDB error: ' + event.target.errorCode);
            db.close();
            _ember['default'].run(null, reject, event);
          };
        });
      });
    },

    /**
     * TODO: See if using index is feasible
     *
     * Find records that matches the query
     * param {object} store DS.Store
     * param {object} type DS.Model
     * param {object} query Query object
     * return {promise} Promise that contains the records
     */
    query: function query(store, type, _query) {
      var _this8 = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _this8.openDatabase().then(function (db) {
          var data = [];
          var modelName = type.modelName;
          var queryKeys = Object.keys(_query);
          var objectStore = db.transaction([modelName]).objectStore(modelName);
          var request = objectStore.openCursor();

          request.onsuccess = function (event) {
            var cursor = event.target.result;

            if (cursor) {
              (function () {
                var queryKeyMatchCount = 0;

                queryKeys.forEach(function (key) {
                  if (cursor.value[key] === _query[key]) {
                    queryKeyMatchCount++;
                  }
                });

                if (queryKeyMatchCount === queryKeys.length) {
                  data.push(cursor.value);
                }

                cursor['continue']();
              })();
            } else {
              db.close();
              _ember['default'].run(null, resolve, data);
            }
          };

          request.onerror = function (event) {
            console.log('IndexedDB error: ' + event.target.errorCode);
            db.close();
            _ember['default'].run(null, reject, event);
          };
        });
      });
    }
  });
});